// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
} from "@material-ui/core";
import { IActiveQuoteData } from "./types";
import {
  classNames,
  formatPrice,
} from "../../../components/src/utilities/utils";
import { withTranslation } from "react-i18next";
import Table from "../../../components/src/Table.web";
import { PlaceOrderDialog } from "./PlaceOrderDialog.web";
import { DeleteQuoteDialog } from "./DeleteQuoteDialog.web";
import Input from "../../../components/src/Input.web";
import { DeleteOutline } from "@material-ui/icons";
import DeleteProductBackendDailog from "../../../components/src/DeleteProductBackendDailog";
import { configJSON } from "./QuotemanagementController";
import { setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End
const QuoteDetailsUpdate: React.FC<IProps> = ({
  loading,
  page,
  activeQuote,
  t,
  submitOrder,
  createNewAddress,
  view,
  submitOrderDialogClose,
  navigateToOrderDetails,
  createdOrderDetails,
  addresses,
  newAddressLoading,
  newAddressSubmitted,
  isOrderLoading,
  isOfferLoading,
  isOrderSubmitted,
  quantityUpdate,
  orderMethod,
  changeOrderMethod,
  deleteQuoteItem,
  cartData,
  fetchCartOnEveryUpdate,
  submitOffer,
  DeleteQuote
}) => {
  const [activeOrder, setActiveOrder] = useState<IState["activOrder"]>([]);
  const [isQuantityNoteOpen,setisQuantityNoteOpen] = useState(false)
  const [totalSum,setTotalSum] = useState<IState["total_sum"]>('')
  const [activeQuoteData, setActiveQuoteData] = useState({
    id: "",
    attributes: {
      customer: { data: { attributes: { customer_code: "" } } },
      total_amount: "",
      total_sum:"",
      delivery_type:"",
      address:{data:{attributes: { 
      address_citya: "",
      address_countya: "",
      address_namea: "",
      address_numbera: "",
      address_postcodea: "",
      address_statea: "",
      address_streeta: "",
      customer_code: "" },id:0,}},
      alternate_address:""
    },
  });
  const [ catalogueDataUpdate , setCatalogueDataUpdate] = useState([]);
  const [event, setEvent] = useState(0);
  const [productIdData, setProductID] = useState(0);
  const tableTitles = React.useMemo(() => {
    const ActiveOrdersTableName = [
      "ProductID",
      "ProductName",
      "Sub-Category",
      "Stock Quantity",
      "Quantity",
      "Total Cost",
    ];
    if (window.location.hash.includes("#checkout")) {
      ActiveOrdersTableName.push("Quantity Arriving", "Lead Days", "Next Arrival" , "");
    }
    else {
       ActiveOrdersTableName.push("");
    }
    if (
      !Array.isArray(activeQuote?.attributes?.order_items?.data) ||
      (Array.isArray(activeQuote?.attributes?.order_items?.data) &&
        activeQuote?.attributes?.order_items?.data === 0)
    ) {
      return ActiveOrdersTableName;
    }

    return ActiveOrdersTableName.map((title) => title);
  }, [activeQuote?.attributes?.order_items?.data]);


  useEffect(() => {
    if (activeQuote?.attributes?.order_items?.data !== undefined) {
      if(event === 0){
        setActiveOrder(activeQuote?.attributes?.order_items?.data);
      }
      const newArray = {
        products: {},
        totalPrice: 0,
        prices: {},
        productDetails: {}
      };
    
    activeQuote?.attributes?.order_items?.data.forEach((item:any) => {
      const productId = item?.attributes?.catalogue?.data?.attributes?.product_id;
      const cartQuantity = event? event : item.attributes.quantity;
      //@ts-ignore
      newArray.products[`id_${productId}`] = {
        ...cartQuantity,
        product_id: productId,
        cart_quantity: productIdData === productId ? cartQuantity : item.attributes.quantity
      };  
       //@ts-ignore
       newArray.productDetails[`id_${productId}`] = item?.attributes?.catalogue?.data?.attributes; 
       setCatalogueDataUpdate(item?.attributes?.catalogue?.data?.attributes)
    });
    
    const dataToStore = {
      products: newArray.products,
      prices: {},
      totalPrice: 0,
      productDetails: newArray.productDetails
    };
    
    localStorage.setItem(`cartData`, JSON.stringify(dataToStore));
    }

    if (activeQuote?.attributes !== undefined) {
      setActiveQuoteData(activeQuote);
    }
    
  }, [activeQuote?.attributes?.order_items?.data, activeQuote,event]);

  const countNullCatalogueUpdate = activeOrder.filter(
    (Item:any) => 
      Item.attributes.catalogue.data === null
  );
  const notNullCatalogueUpdate = activeOrder.filter(
    (orderItem:any) => 
      orderItem.attributes.catalogue.data !== null
  );
  const enterQuantity = (event : React.ChangeEvent<HTMLInputElement> , item  :any) => {
    let value = parseInt(event.target.value);
    if (isNaN(value) || value < 1) {
      value = 1;
      event.target.value = value.toString();
     }
    const isInStock = value <= item.attributes.catalogue.data.attributes.stock_qty
    if (isInStock) {
      quantityUpdate(item.attributes.catalogue.data.attributes.product_id, value);
      const updatedActiveOrder : OrderItem[] = activeOrder.map((data: OrderItem) => {
        return data.id === item.id ? { ...data, attributes: { ...data.attributes, quantity: value, total_cost: Number(item.attributes.price) * value } } : data
      })   
      setActiveOrder(updatedActiveOrder)
      setTimeout(()=>{
        const data : number = updatedActiveOrder?.map((item:OrderItem)=> Number(item.attributes.total_cost)).reduce((sum, amount) => sum + amount, 0)
        setTotalSum(data)
      },100)
      setEvent(value);
      setProductID(item.attributes.catalogue.data.attributes.product_id)
    } else {
      handleIsQuantityNoteOpen()
    }
  }
  const renderSubmitButtonContent = () => {
    return isOfferLoading ? (
      <CircularProgress color="primary" size={24} />
    ) : (
      <span>{t('SubmitQuote')}</span>
    );
  }

  const handleIsQuantityNoteOpen = () => {
    setisQuantityNoteOpen(prev=>!prev)
  }
  const renderTotalSum = () => {
   return totalSum ? totalSum : activeQuoteData.attributes.total_sum
  }
  const displayButtonsConditions = () => {
    return catalogueDataUpdate === undefined && activeOrder.length===1 || notNullCatalogueUpdate.length ===0
  }
  const handleMount = async () => {
    setTotalSum("")
  }
  const handleSaveQuotes = () => {
    fetchCartOnEveryUpdate()
    setEvent(0)
  }
  const handleBackButton = async () => {
    await setStorageData("UPDATE", "DETAILS")
  }
  useEffect(() => {
    const handlePopState = () => {
      !window.location.hash.includes('#checkout') && handleBackButton();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  useEffect(() => {
    handleMount()
  }, [])
  // Customizable Area End
  return (
    // Customizable Area Start

    <>
     {
     countNullCatalogueUpdate?.length >= 1 ? 
      <DeleteProductBackendDailog
      //@ts-ignore
       t={t}
       countNullCatalogue={countNullCatalogueUpdate}
       activeOrder= {activeOrder}
       onDeleteQuote={() => {
        DeleteQuote( window.location.hash.substring(1)) 
      }} 
      notNullCatalogue={notNullCatalogueUpdate}                     
       /> : ''
    }
      {loading ? (
        <div className="loading-section" data-testid="active-order-loading">
          <CircularProgress color="primary" style={{ width: 64, height: 64 }} />
        </div>
      ) : (
        <div className="active-order-section">
          <Grid container className="top-section" spacing={3}>
            <Grid item className="order-details" component="div" md={7}>
            <Typography variant="h1" className="page-title" component="h1">
                  {t('SavedQuotes')}
            </Typography>
              <Typography
                variant="h1"
                component="h1"
                data-testid="order-id-title"
              >
                {t("Cart_ID")} {activeQuoteData.id}
              </Typography>
              <Paper elevation={0} className="order-section-card">
                <div className="order-detail-item">
                  <Typography variant="subtitle1" component="strong">
                    {t("Customer_ID")}
                  </Typography>
                  <span data-testid="customer-id">
                    {
                      activeQuoteData.attributes.customer.data.attributes
                        .customer_code
                    }
                  </span>
                </div>

                <div className="order-detail-item">
                  <Typography variant="subtitle1" component="strong">
                    {t("Total_Amount")}
                  </Typography>
                  <span data-testid="total-fees">
                    € {formatPrice(renderTotalSum())}
                  </span>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Table
            data-test-id = "SavedQuoteUpdateTable"
            stickyHeader
            className="orders-products-table"
            titles={tableTitles}
            items={notNullCatalogueUpdate?.map((item: any) => {
              const pid = `id_${item.attributes.catalogue.data.attributes.product_id}`;
              const isProductExist = !!cartData.products[pid];
              const productQuantity = isProductExist
                ? cartData.products[pid].cart_quantity
                : 1;

              const stockStatus =
                item.attributes.catalogue.data.attributes.stock_status;
              const nextArrival =
                stockStatus === "in_stock"
                  ? "-"
                  : item.attributes.catalogue.data.attributes.next_delivery_date;

              return {
                product_id:
                  item.attributes.catalogue.data.attributes.product_id,
                product_name:
                  item.attributes.catalogue.data.attributes.name ,
                sub_category:
                  item.attributes.catalogue.data.attributes.sub_category.name ,
                stock_qunatity : item.attributes.catalogue.data.attributes.stock_qty,
                quantity: (
                  <div className="quantity-input">
                    <Input
                      data-test-id="input-quantity-id"
                      defaultValue={
                        isProductExist
                          ? productQuantity
                          : item.attributes.quantity
                      }
                      onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                        enterQuantity(event, item);
                      }}
                      type="number"
                      disabled={item.attributes.catalogue.data === null}
                    />
                    {item.attributes.catalogue.data.attributes.base_unit ||
                      item.attributes.static_base_unit}
                  </div>
                ),
                total_Cost: (
                  <div className="price">
                    <span className="span">€</span><span>{formatPrice(Number(item.attributes.price) * item.attributes.quantity)}</span>
                  </div>
                ),
                ...(window.location.hash.includes("#checkout") && {
                  quantityArriving:
                    item.attributes.catalogue.data.attributes.quantity_arriving ||
                    "-",
                  leadDays:
                    item.attributes.catalogue.data.attributes.lead_days || "-",
                  nextArrival,
                }),
                actions: (
                  <div className="actions">
                    {item.attributes.catalogue.data === null ? (
                      <IconButton className="delete-button">
                        <DeleteOutline />
                      </IconButton>
                    ) : (
                      <DeleteQuoteDialog
                      data-test-id = "delete-quote-dialog"
                        t={t}
                        onFormSubmit={() => deleteQuoteItem(item.id)}
                      />
                    )}
                  </div>
                ),
              };
            })}
          />
          <div
            className={classNames({
              "cart-table-footer": true,
              "__is-checkout": page === "SAVED_QUOTE",
            })}
          >
            <strong className="total-cart-amount">
              {t("TotalQuoteAmount")}
            </strong>
            <span className="price" style={{fontWeight : 'bold'}}>
            € {formatPrice(renderTotalSum())}
            </span>
          </div>
          <div className="button-group" style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
            <div className="order-methods" style={{marginBottom:2 }}>
                  <RadioGroup aria-label="" name="" value={orderMethod} onChange={changeOrderMethod}>
                    <FormControlLabel value="PARTIAL_DELIVERY" control={<Radio color="primary" />} label={t(configJSON.needPartialDeliveryText)} />
                    <FormControlLabel value="FINAL_DELIVERY_ONLY" control={<Radio color="primary" />} label={t(configJSON.needFinalDeliveryText)} />
                  </RadioGroup>
                  <div className="options">
                  </div>
                </div>
                <Box style={webStyle.actionButtonContainer}>
            {event!== 0 ?
             <Button variant="contained" color="primary" onClick={handleSaveQuotes} style={webStyle.actionButton}>
              {t("SavedQuotes")}             
            </Button> :
            <Button variant="outlined" onClick={handleSaveQuotes} style={webStyle.actionButton} color="primary" disabled={event === 0}>
                {t("EditQuote")}
              </Button>
            }            
            {
             displayButtonsConditions() ? 
             <Button variant="outlined" color="primary" disabled fullWidth style={webStyle.actionButton}>
             {t("ConfirmedQuotes")}
           </Button>:
                 <Button
                 data-test-id='submit-order-button-update'
                 variant="outlined"
                 disabled={isOrderLoading}
                 color="primary"
                 onClick={() => submitOffer("")}
                 style={webStyle.actionButton}
             >
                  {renderSubmitButtonContent()}
             </Button>
              }

           {
             displayButtonsConditions()? 
             <Button variant="outlined" color="primary" disabled fullWidth style={webStyle.actionButton}>
             {t("PlaceOrder")}
           </Button>:
              <PlaceOrderDialog
                page={page}
                t={t}
                view={view}
                isLoading={Object.keys(loading).length > 0 || isOrderLoading}
                isSubmitted={isOrderSubmitted}
                submitOrder={submitOrder}
                onDialogClose={submitOrderDialogClose}
                addresses={addresses}
                createdOrderDetails={createdOrderDetails}
                navigateToOrderDetails={navigateToOrderDetails}
                createNewAddress={createNewAddress}
                createNewAddressLoading={newAddressLoading}
                isRequestAddressSubmitted={newAddressSubmitted}
                orderMethod={orderMethod}
                changeOrderMethod={changeOrderMethod}
                default_address_id={activeQuoteData?.attributes?.address?.data?.id.toString()}
                default_address={activeQuoteData?.attributes.alternate_address}
              />
            }
            </Box>
              <Dialog aria-labelledby="customized-dialog-title" open={isQuantityNoteOpen} onClose={handleIsQuantityNoteOpen}>
                <DialogContent >
                  <Typography>
                    {t("OrderQuantityIsInStockWrong")}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleIsQuantityNoteOpen}
                   data-test-id="isQuantityNoteOpenBtn" 
                   variant="contained" color="primary">
                    {t("OK")}
                  </Button>
                </DialogActions>
              </Dialog>
          </div>
        </div>
      )}
    </>
    // Customizable Area End
  );
};

// Customizable Area Start

interface IProps {
  page: "SAVED_QUOTE" | "CONFIRMED_QUOTE" ;
  activeQuote: IActiveQuoteData;
  t: any;
  loading: any;
  id: any;
  submitOrder?: (addressId: string, alternateAddress?: string) => void;
  view: "LIST" | "DETAILS" | 'UPDATE'| 'CONFIRM_DETAILS';
  createNewAddress: (address: string) => void;
  submitOrderDialogClose: any;
  navigateToOrderDetails: any;
  createdOrderDetails: any;
  addresses: any;
  newAddressLoading: boolean;
  newAddressSubmitted: boolean;
  isOrderLoading: boolean;
  isOrderSubmitted: boolean;
  quantityUpdate: any;
  orderMethod:any;
  changeOrderMethod: (method: any) => void;
  cart?: any;
  deleteQuoteItem:any;
  quantity:number;
  product_id:any;
  submitOffer:(addressId: string, alternateAddress?: string, deliveryType?:string) => void;
  cartData:any;
  fetchCartOnEveryUpdate:()=>void;
  DeleteQuote:(quote_id:any)=>void;
  isOfferLoading:boolean;
}

interface IState {
  total_sum : string | number,
  activOrder : OrderItem[],
}
interface OrderAttributes {
  price: string;
  quantity: number;
  total_cost: number;
}

interface OrderItem {
  id: string;
  attributes: OrderAttributes;
}

const webStyle = {
  actionButtonContainer: {
    width: '70%',
    display: 'flex',
    justifyContent: "flex-end",
    alignItems: 'flex-start',
    flexWrap: "wrap" as "wrap",
  },
  actionButton: {
    minWidth: '300px',
    marginBottom: '12px',
    marginRight: '12px',
  },
}
export default withTranslation()(QuoteDetailsUpdate);
// Customizable Area End
