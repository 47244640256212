import React from 'react';
import { Dialog as MuiDialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons'

class Dialog extends React.Component<React.PropsWithChildren<IProps>, IState> {
    constructor(props: React.PropsWithChildren<IProps>) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);

        this.state = { isDialogOpened: false }
    }

    openDialog() {
        this.setState({ isDialogOpened: true });
    }

    closeDialog() {
        this.setState({ isDialogOpened: false });
        if (typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
    }

    render() {
        const { closable = false, title, id, disablePortal = false } = this.props;

        return (
            <MuiDialog
                disableEscapeKeyDown
                id={id}
                className="custom-dialog"
                onClose={(_, reason) => {
                    if (reason === 'backdropClick') {
                        return
                    }
                    this.closeDialog()
                }}
                open={this.state.isDialogOpened}
                BackdropProps={{
                    style: { backgroundColor: 'rgba(0,0,0,0.3)' }
                }}
                PaperProps={{
                    className: 'custom-dialog-wrapper',
                    elevation: 5,
                }}
                disablePortal={disablePortal}
            >
                {title && (
                    <Typography
                        className="dialog-title"
                        component="h6"
                        variant="h2"
                        color="primary"
                    >
                        {title}
                    </Typography>
                )}

                {closable && (
                    <IconButton className="close-button" onClick={this.closeDialog}>
                        <CloseOutlined />
                    </IconButton>
                )}

                <DialogContent>
                    {this.props.children}
                </DialogContent>
            </MuiDialog>
        )
    }
}

interface IProps {
    id?: string,
    title?: string,
    closable?: boolean,
    onClose?: () => void,
    disablePortal?: boolean
}

interface IState {
    isDialogOpened: boolean
}

export default Dialog;