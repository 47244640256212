import React from "react";
// Customizable Area Start
import {
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MainLayout from "../../../components/src/MainLayout.web";
import Table from "../../../components/src/Table.web";
import Input from "../../../components/src/Input.web";
import { classNames, formatPrice } from "../../../components/src/utilities/utils";
import LanguageHelper from '../../../components/src/utilities/LanguageHelper';
import { withTranslation } from "react-i18next";
import './shoppingcart.css';
import { SubmitQuoteDialog } from "./SubmitQuoteDialog.web.";
const configJSON = require("./config");
import ShoppingCartOrdersController, {
  Props,
} from "./ShoppingCartOrdersController.web";
import { SubmitOffersDialog } from "./SubmitOffersDialog";
import CartDeletedBeDailog from "../../../components/src/CartDeletedBeDailog";
// Customizable Area End

import SuccessOrderDialog from "./SubmitOrderDialog";
import DeleteCartItemDialog from "./DeleteCartItemDialog.web";
import { ICartProduct, IProduct } from "../../../components/src/types/types";


export class ShoppingCartOrdersWeb extends ShoppingCartOrdersController {
  static requestNewAddress() {
    throw new Error("Method not implemented.");
  }
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.renderItem = this.renderItem.bind(this);
    this.renderItems = this.renderItems.bind(this);
    this.getTableTitles = this.getTableTitles.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  renderItem({
    type,
    product,
    productDetails
  } : RenderTableItemArgs) {

    const pid = `id_${product.product_id}`
    const isProductLoading = this.state.loading[product.id];
    const isProductExist = !!this.state.cart.products[pid];
    const productQuantity = isProductExist ? this.state.cart.products[pid].cart_quantity : 1;
    const productUnitPrice = this.state.cart.prices[pid] || 0;
 
    switch (type) {
      case 'name':
        return (
          <div className="product-name">
            <Typography className="name">{LanguageHelper.getLangText(productDetails.name)}</Typography>
          </div>
        );
      case 'quantity':
        return (
          <div className="quantity-input">
            <Input
                data-test-id = 'quantityInput'
                defaultValue={isProductExist ? productQuantity : ''}
                onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.quantityUpdate(product.product_id, parseInt(event.target.value));
                }}
                disabled={isProductLoading}
                type="number"
            />
    
            {LanguageHelper.getLangText(productDetails.base_unit)}.
          </div>
        );
      case 'price':
        return (
          <div className="price">
             <span className="span">€</span><span>{formatPrice(productUnitPrice)}</span>
          </div>
        );
      case 'totalAmount':
        return (
          <div className="price">
             <span className="span">€</span><span>{formatPrice(productUnitPrice * productQuantity)}</span>
          </div>
        );
      case 'stock':
        if (product.cart_quantity <= productDetails.stock_qty) {
          return LanguageHelper.getLangText('In Stock');
        }

        return (
          <div className="stock-item">
            <span className="stock-qty">{productDetails.stock_qty} {LanguageHelper.getLangText('Pcs Av')}.</span>
            <small className="last-update">{LanguageHelper.getLangText('Last Update')}: {new Date().toLocaleDateString('en-GB')}</small>
          </div>
        );
      case 'nextArrival':
        return this.handleIsStockAvailble(productDetails,'nextArrival');
      case 'quantityArriving':
        return this.handleIsStockAvailble(productDetails,'quantityArriving');
      case 'leadDays':
        return productDetails.lead_days || '—';
      case 'action':
        if (isProductLoading) {
          return (
            <div>
              <CircularProgress
                color="primary"
                style={{ width: 36, height: 36 }}
              />
            </div>
          )
        }

        return (
          <DeleteCartItemDialog
            data-testid = "DeleteCartItemDialog"
            onFormSubmit={() => {
              this.quantityUpdate(product.product_id, 0);
            }}
          />
        );
      default:
        return null;
    }
  }

  renderItems() {
    const values: HTMLElement[] = [];
    const {validIDs} = this.state 
    Object.entries(this.state.cart.products).filter(([pid]) => validIDs.includes(pid))
    .forEach(([pid, product]) => {
      const productDetails = this.state.cart.productDetails[pid];

      const name = this.renderItem({ type: 'name', product, productDetails });
      const quantity = this.renderItem({ type: 'quantity', product, productDetails });
      const price = this.renderItem({ type: 'price', product, productDetails });
      const totalAmount = this.renderItem({ type: 'totalAmount', product, productDetails });
      const stock = this.renderItem({ type: 'stock', product, productDetails });
      const nextArrival = this.renderItem({ type: 'nextArrival', product, productDetails });
      const quantityArriving = this.renderItem({ type: 'quantityArriving', product, productDetails });
      const leadDays = this.renderItem({ type: 'leadDays', product, productDetails });
      const action = this.renderItem({ type: 'action', product, productDetails });

      let items: any = { name, quantity, price, totalAmount, stock, nextArrival };

      if (this.state.page === 'CART') {
        items = { ...items, action }
      } else {
        items = { ...items, quantityArriving, leadDays, action }
      }

      values.push(items);
    });

    return values;
  }

  getTableTitles() {
    const { cart, page } = this.state;
    const { t }= this.props
    let titles = ['Product', 'Quantity', 'Price/Mt', 'Total_Amount', 'Stock', 'Next_Arrival'];

    if (page === 'CART') {
      titles = [...titles, ''];
    } else if (page === 'CHECKOUT') {
      titles = [...titles, 'Quantity_Arriving', 'Lead_Days', ''];
    }

    const products = Object.entries(cart.products);
    if (products.length > 0) {
      const firstBaseUnit = cart.productDetails[products[0][0]].base_unit;
      const isAllUnitsSame = products.every(([pid]) => {
        const productDetails = (cart.productDetails[pid] || {});
        return productDetails.base_unit === firstBaseUnit;
      });

      titles[2] = isAllUnitsSame ? `${t("price")}/${firstBaseUnit}` : t("Price/Unit");
    }

    return titles.map((title) => LanguageHelper.getLangText(title));
  }

  renderAddressTypeSelector = (productLength:number) => {
    const { t }= this.props;
    return this.handleUrlIncludesCheckout() && productLength !==0 && <RadioGroup aria-label="" name="" value={this.state.orderMethod} onChange={this.changeOrderMethod} >
      <FormControlLabel value="PARTIAL_DELIVERY" control={<Radio color="primary" />} label={t(configJSON.needPartialDeliveryText)} />
      <FormControlLabel value="FINAL_DELIVERY_ONLY" control={<Radio color="primary" />} label={t(configJSON.needFinalDeliveryText)} />
    </RadioGroup>
  }

  handleIsLoading = (loadingItem : boolean) => {
    return Object.keys(this.state.loading).length > 0 || loadingItem
  } 
  renderTitle = (productsLength : number) => {
    const { t }= this.props;
    return t(productsLength === 0  || !this.handleUrlIncludesCheckout() && this.state.page === 'CART' ? configJSON.cartPageTitle : configJSON.checkoutPageTitle)
  }

  handleIsAnyLoading = () => {
  return Object.keys(this.state.loading).length > 0 || this.state.isQuoteLoading || this.state.isOfferLoading || this.state.isOrderLoading
  }

  handleDispayDialog  = () => { 
   return Boolean(this.state.isOrderSubmitted && this.state.createdOrderDetails)
  }

  handleDisplayEmptyCartMessage = (productsLength:number) => {
    const { t }= this.props;
    return productsLength===0?t(configJSON.noCartFound):""
  }

  handleCartLengthAndOrderId = (cartLength : number) => {
    const {createdOrderDetails} = this.state;
   return cartLength > 0 || (createdOrderDetails && createdOrderDetails.id)
   }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { cart, page, createdOrderDetails ,isStockCheck, isError} = this.state;
    const { t }= this.props;
    const cid = localStorage.getItem('customer_id');
    const cartCountString = localStorage.getItem(`cart_${cid}`);
    let productsLength = 0;
    
    if (cartCountString) {
      const cartCount = JSON.parse(cartCountString);
      productsLength = Object.keys(cartCount.products).length;    
    }
    let LIST_ITEMS: { title: string; value: string; }[] = [];
    
    if (createdOrderDetails) {
      LIST_ITEMS = [
        { title: 'Customer_ID', value: createdOrderDetails.customer_id },
        { title: 'Order_Date', value: createdOrderDetails.created_at },
        { title: 'Total_Amount', value: `€ ${createdOrderDetails.total_amount}` },
        { title: 'Status', value: createdOrderDetails.status },
      ]
    }
    return (     
      <MainLayout
        id="cart"
        navigation={this.props.navigation}
        useSidebar={false}
        cartCount={productsLength}
      >
        <Typography variant="h1" component="h1" className="page-title">
          {this.renderTitle(productsLength)} <small>({productsLength} {t('Items')})</small>
        </Typography>
        
         {
          isError === true ?<CartDeletedBeDailog 
          //@ts-ignore
          t={t}/>:''
         }

        {this.state.isCartLoading ? (
          <div className="loading-section">
            <CircularProgress
                color="primary"
                style={{ width: 64, height: 64 }}
            />
          </div>
        ) : (
          <>
            {this.handleCartLengthAndOrderId((Object.keys(cart.products).length)) ? (
              <>
           {this.handleShowCart(productsLength) && <Table
                  stickyHeader
                  className={classNames({
                    'cart-table': true,
                    '__is-checkout': page === 'CHECKOUT'
                  })}
                  titles={this.getTableTitles()}
                  items={this.renderItems()}
                >
                  <div
                    className={classNames({
                      'cart-table-footer': true,
                      '__is-checkout': page === 'CHECKOUT'
                    })}
                  >
                    <strong className="total-cart-amount">{t('TotalCartAmount')}</strong>
                    <span className="price">€ {formatPrice(cart.totalPrice)}</span>
                  </div>
                </Table>
                }

                <div className="cart-page-footer" style={{display:"flex",justifyContent:`${this.handleUrlIncludesCheckout() ? 'space-between' : 'flex-end'}`,flexWrap:"wrap",}}>
                  {this.renderAddressTypeSelector(productsLength)}
                 {productsLength !== 0 && <div className="button-group" style={{marginLeft:"auto"}}>
                  <SubmitQuoteDialog
                      isLoading={this.handleIsLoading(this.state.isQuoteLoading)}
                      isAnyloading={this.handleIsAnyLoading()}
                      hasCartProducts={Object.keys(this.state.cart.products).length > 0}
                      isSubmitted={this.state.isQuoteSubmitted}
                      saveQuote={this.saveQuote}
                      onDialogClose={this.submitOrderDialogClose}
                      orderMethod={this.state.orderMethod}
                      hasStockIssue={this.state.hasStockIssue}
                      addresses={this.state.addresses}
                      createdQuoteDetails={this.state.createdQuoteDetails}
                      navigateToQuoteDetails={this.navigateToQuoteDetails}
                      page={this.state.page}
                      createNewAddress={this.createNewAddress}
                      createNewAddressLoading={this.state.newAddressLoading}
                      isRequestAddressSubmitted={this.state.newAddressSubmitted}
                      t={t}
                    /> 
                   
                    <SubmitOffersDialog
                      isLoading={this.handleIsLoading(this.state.isOfferLoading)}
                      isAnyloading={this.handleIsAnyLoading()}
                      hasCartProducts={Object.keys(this.state.cart.products).length > 0}
                      isSubmitted={this.state.isOfferSubmitted}
                      submitOffers={this.submitOffers}
                      onDialogClose={this.submitOrderDialogClose}
                      orderMethod={this.state.orderMethod}
                      hasStockIssue={this.state.hasStockIssue}
                      addresses={this.state.addresses}
                      createdQuoteDetails={this.state.createdQuoteDetails}
                      navigateConfirmeQuoteDetails={this.navigateConfirmeQuoteDetails}
                      page={this.state.page}
                      createNewAddress={this.createNewAddress}
                      createNewAddressLoading={this.state.newAddressLoading}
                      isRequestAddressSubmitted={this.state.newAddressSubmitted}
                      t={t}
                      isStockCheck={isStockCheck}
                      changeOrderMethod={this.changeOrderMethod}
                    />

                    <SuccessOrderDialog
                      isLoading={this.handleIsLoading(this.state.isOrderLoading)}
                      isAnyloading = {this.handleIsAnyLoading()}
                      hasCartProducts={Object.keys(this.state.cart.products).length > 0}
                      isSubmitted={this.state.isOrderSubmitted}
                      submitOrder={this.submitOrder}
                      onDialogClose={this.submitOrderDialogClose}
                      orderMethod={this.state.orderMethod}
                      hasStockIssue={this.state.hasStockIssue}
                      addresses={this.state.addresses}
                      createdOrderDetails={this.state.createdOrderDetails}
                      navigateToOrderDetails={this.navigateToOrderDetails}
                      page={this.state.page}
                      createNewAddress={this.createNewAddress}
                      createNewAddressLoading={this.state.newAddressLoading}
                      isRequestAddressSubmitted={this.state.newAddressSubmitted}
                    />
                  </div>
                  }

                </div>
              </>
            ) : (
              <div className="no-cart-found">
                {this.handleDisplayEmptyCartMessage(productsLength)}
              </div>
            )}

              {this.handleDispayDialog() && <Dialog
                id="submit-order-dialog"
                PaperProps={{
                  style: {
                    padding: '0vh 6vh',
                    borderRadius:'12px'
                  },
                }}
                BackdropProps={{
                  style: {
                    backgroundColor: 'rgba(0, 0, 0, .6)',
                  },
                }}

                ref={this.dialogRef}
                disablePortal
                open={this.handleDispayDialog()}
              >

                <div className="success-view">
                  <CheckCircleIcon className="check-icon" />
                  <Typography component="strong" className="action">{t('OrderSuccess')}</Typography>
                  <Typography component="strong" className="order-id">{t('Order_ID')} {this.state.createdOrderDetails?.id}</Typography>
                  <div className="list">
                    {LIST_ITEMS.map((item: { title: string; value: string; }) => (
                      <div className="list-item" key={item.title}>
                        <Typography component="strong">{t(item.title)}</Typography>
                        <Typography component="span">{item.value}</Typography>
                      </div>
                    ))}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.navigateToOrderDetails}
                      className="view-order-button"
                    >
                      {t(configJSON.viewOrderText)}
                    </Button>
                  </div>
                </div>
              </Dialog>}
            </>
        )}
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.isQuantityNoteOpen}>
          <DialogContent >
            <Typography>
              {t("OrderQuantityIsWrong")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleIsQuantityNoteOpen(false)} data-test-id = "isQuantityNoteOpenBtn" variant="contained" color="primary">
              {t("OK")}
            </Button>
          </DialogActions>
        </Dialog>
      </MainLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
interface RenderTableItemArgs {
  type: 'name' | 'quantity' | 'price' | 'totalAmount' | 'stock' | 'nextArrival' | 'quantityArriving' | 'leadDays' | 'action',
  product: ICartProduct,
  productDetails: IProduct
}

export default withTranslation()(ShoppingCartOrdersWeb);

// Customizable Area End
